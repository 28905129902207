<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 11:46:23
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-09-19 13:51:21
-->
<template>
    <div :class="['register-page', $i18n.locale]">
        <div class="navbar">
            <van-icon name="arrow-left" class="back" @click="$router.push('/login')" />
            <el-dropdown placement="bottom" class="custom-dropdown" @command="onCommand" trigger="click">
                <span class="el-dropdown-language">{{ currentLanguage }} </span>
                <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
                    <el-dropdown-item :command="item.label" v-for="(item, index) in languages" :key="index"
                        :disabled="item.label === currentLanguage">{{ item.label }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="header">
            <h2>{{ $t('login.loginAndRegister') }}</h2>
            <p class="tipsInfo" v-if="!isRegInfo">{{ $t('register.title') }}</p>
            <p class="tips" v-else>{{ $t('login.btn.title') }}</p>
        </div>
        <!-- 登录选择 -->
        <div class="login" v-show="!isRegInfo">
            <div @click="selectMobile">
                <span :class="{ 'mobile-login': loginStatus }">{{ $t('register.mobileRegister') }}</span>
            </div>
            <span class="icon">|</span>
            <div @click="selectEmail">
                <span :class="{ 'mobile-login': !loginStatus }">{{ $t('register.emailRegister') }}</span>
            </div>
        </div>
        <!-- 手机号绑定页面 -->
        <div class="content" v-if="loginStatus" v-show="!isRegInfo">
            <div class="form">
                <div class="item">
                    <label for="mobile">
                        <div class="area" @click="selectAreaCode">
                            <span class="no">{{ areaCode }}</span>
                            <i class="arrow_bottom"></i>
                        </div>
                    </label>
                    <div class="info">
                        <input autocomplete="off" @blur="handleBlurReset($event)" @focus="handleFouceReset($event)" label=""
                            name="mobile" type="digit" v-model.trim="form.phone"
                            :placeholder="$t('login.formList[0].label')" :border="false" />
                        <!-- <i @click="handleClearMobile" class="icon-del" v-if="form.phone"></i> -->
                    </div>
                </div>
                <div class="item">
                    <label for="verifyCode">{{ $t('login.formList[1].label') }}</label>
                    <div class="info">
                        <div class="placeholder_code" v-if="!isFocus && $i18n.locale === 'ja-JP'" @click="onPlaceholder">
                            {{ $t('login.formList[1].placeholder') }}
                        </div>
                        <van-field ref="verifyCode" v-else :disabled="isCodeInputDisabled" @blur="handleBlurReset($event)"
                            @focus="handleFouceReset($event)" @input="validatorCode" v-model="form.code" type="number"
                            label="" :placeholder="$i18n.locale === 'ja-JP' ? '' : $t('login.formList[1].placeholder')"
                            name="verifyCode" :border="false" />
                        <span :class="{ 'is-disabled': isCodeBtnDisabled }" @click="handleSendCode" class="get-code-btn">{{
                            codeText }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 邮箱注册页面 -->
        <div class="content" v-else v-show="!isRegInfo">
            <div class="form">
                <div class="item">
                    <label for="email">E-mail</label>
                    <div class="info">
                        <van-field autocomplete="off" @blur="handleBlurReset($event)" @focus="handleFouceReset($event)"
                            v-model.trim="form.email" name="" label="" :placeholder="$t('login.formList[2].label')"
                            :rules="[{ required: true, message: '请输入邮箱' }]" />
                    </div>
                </div>
                <div class="item">
                    <label for="verifyCode">{{ $t('login.formList[1].label') }}</label>
                    <div class="info">
                        <div class="placeholder_code" v-if="!isFocus && $i18n.locale === 'ja-JP'" @click="onPlaceholder">
                            {{ $t('login.formList[1].placeholder') }}
                        </div>
                        <input :style="$i18n.locale === 'ja-JP' ? 'padding-left: 20px' : 'padding-left: 50px'"
                            autocomplete="off" ref="verifyCode" v-else :disabled="isCodeInputDisabled" v-model="form.code"
                            @blur="handleBlurReset($event)" @focus="handleFouceReset($event)" @input="validatorCode"
                            type="number" label=""
                            :placeholder="$i18n.locale === 'ja-JP' ? '' : $t('login.formList[1].placeholder')"
                            name="verifyCode" :border="false" />
                        <span :class="{ 'is-disabled': isCodeBtnDisabledEmail }" @click="handleSendCode"
                            class="get-code-btn">{{ codeText }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="content" v-show="!isRegInfo">
            <div class="form">
                <div class="rule" v-if="$i18n.locale === 'ja-JP'" @click="onRuleChange">
                    <span :class="[{ 'is-check': isSelectRule }, 'checkbox']">
                        <input type="checkbox" v-model="isSelectRule" />
                    </span>
                    <span @click="goToUser('UserSecret')">{{ $t('register.needRead.privacy') }}</span>
                    <i>{{ $t('register.needRead.agree') }}</i>
                </div>
                <div class="rule" v-if="$i18n.locale === 'zh-CN' || $i18n.locale === 'en-US'" @click="onRuleChange">
                    <span :class="[{ 'is-check': isSelectRule }, 'checkbox']">
                        <input type="checkbox" v-model="isSelectRule" />
                    </span>
                    <i>{{ $t('register.needRead.agree') }}</i>
                    <span @click="goToUser('UserSecret')">{{ $t('register.needRead.privacy') }}</span>
                </div>
                <!-- 1.12.0版本去掉下次自动登录 -->
                <!-- <div class="rule" >
                                                                                <span :class="[{ 'is-check': isAutoLogin }, 'checkbox']" @click="onAutoLogin()">
                                                                                    <input type="checkbox" v-model="isAutoLogin" />
                                                                                </span>
                                                                                <i @click="onAutoLogin()">{{ $t('login.autoLogin') }}</i>
                                                                            </div> -->

                <div class="submit-btn">
                    <div class="error-tips" v-if="erorTips">{{ erorTips }}</div>
                    <van-button :disabled="isMobileSubmitDisabled" :class="{
                        'is-disabled': isMobileSubmitDisabled,
                        'custom-btn': true,
                    }" @click.prevent="handleMobileSumbit" @keyup.enter="handleMobileSumbit" type="primary"
                        color="linear-gradient(90deg, #00E3C9 0%, #009FE8 100%)">{{ $t('register.register') }}
                    </van-button>
                </div>
            </div>
        </div>
        <!-- 用户信息注册页面 -->
        <div class="content user-info" v-show="isRegInfo">
            <div class="form">
                <div class="item itemName">
                    <van-field v-model="name" type="text" maxlength="100" :label="$t('register.form.labelList[0]')"
                        :placeholder="$t('register.placeholder[0]')" :border="false" />
                </div>
                <div class="item">
                    <label for="mobile">{{ $t('register.form.labelList[1]') }}</label>
                    <div class="info center itemSex">
                        <span :class="[{ 'is-active': form.sex === 1 }, 'sex']" @click="form.sex = 1">{{
                            $t('register.sexOptions.male') }}
                        </span>
                        <span :class="[{ 'is-active': form.sex === 2 }, 'sex', 'sex2']" @click="form.sex = 2">{{
                            $t('register.sexOptions.female') }}
                        </span>
                    </div>
                </div>
                <div class="item itemSpan">
                    <span class="span-cm" v-if="isShowHeight === 0">
                        <div v-if="$i18n.locale === 'it-IT'" class="default_height">
                            <label>
                                {{ $t('register.form.labelList[2]') }}
                            </label>
                            <div class="placeholder_code" @click="onHeightFocus">
                                {{ $t('register.placeholder[1]') }}
                            </div>
                        </div>
                        <van-field v-else @focus="onHeightFocus" v-model="height.value" type="number"
                            :label="$t('register.form.labelList[2]')" :placeholder="$t('register.placeholder[1]')"
                            :border="false" />
                    </span>
                    <span v-else-if="isShowHeight === 1" class="metric">
                        <van-field @blur="onBlurMetric" ref="metricRef" :maxlength="3" v-model="height.value" type="number"
                            :label="$t('register.form.labelList[2]')" :border="false" />
                        <span class="unit">cm</span>
                    </span>
                    <span v-else-if="isShowHeight === 2" class="metric imperial">
                        <div class="subItem firstItem">
                            <van-field @blur="onBlurImperial" ref="imperialRef" :maxlength="1" v-model="usrForm.ft"
                                type="number" :label="$t('register.form.labelList[2]')" :border="false" />
                            <span class="unit unit-ft">ft</span>
                        </div>
                        <div class="subItem subItem1">
                            <van-field @blur="onBlurImperial" :maxlength="2" v-model="usrForm.in" type="number" label=""
                                :border="false" />
                            <span class="unit">in</span>
                        </div>
                    </span>
                    <span class="ext" @click="onExt">
                        <img src="@/assets/images/register/toggle_button.png" alt="">
                        {{ ext }}
                    </span>
                </div>

                <div class="item">
                    <van-field v-if="!isShowAge" @focus="onAgeFocus" v-model="age" type="number"
                        :label="$t('register.form.labelList[3]')" :placeholder="$t('register.placeholder[2]')"
                        :border="false" />
                    <span v-else class="metric age">
                        <van-field @blur="onBlurAge" ref="ageRef" :maxlength="3" v-model="age" type="number"
                            :label="$t('register.form.labelList[3]')" :border="false" />
                        <span class="unit">{{ $t('register.unit[2]') }}</span>
                    </span>
                </div>
                <div class="rule" v-if="$i18n.locale === 'ja-JP'" @click="onRuleChange">
                    <span :class="[{ 'is-check': isSelectRule }, 'checkbox']">
                        <input type="checkbox" v-model="isSelectRule" />
                    </span>
                    <span @click="goToUser('UserSecret')">{{ $t('register.needRead.privacy') }}</span>
                    <i>{{ $t('register.needRead.agree') }}</i>
                </div>
                <div class="rule" v-if="$i18n.locale !== 'ja-JP'" @click="onRuleChange">
                    <span :class="[{ 'is-check': isSelectRule }, 'checkbox']">
                        <input type="checkbox" v-model="isSelectRule" />
                    </span>
                    <i>{{ $t('register.needRead.agree') }}</i>
                    <span @click="goToUser('UserSecret')">{{ $t('register.needRead.privacy') }}</span>
                </div>
                <div class="submit-btn">
                    <p :class="[{ opacity: errorMg === '-' }, 'tips', 'error-tips']">
                        {{ errorMg }}
                    </p>
                    <van-button v-if="!submitDisabled" :class="{
                        'is-disabled': isSubmitDisabled,
                        complete: true,
                        'no-disabled': !isSubmitDisabled,
                    }" :disabled="isSubmitDisabled" @click.prevent="handleInfoSumbit" @keyup.enter="handleInfoSumbit"
                        type="primary">{{ $t('register.btn') }}
                    </van-button>
                </div>
            </div>
        </div>
        <!-- <p class="tips" v-if="!isRegInfo">{{ $t('login.btn.title') }}</p> -->
        <area-code v-if="showAreaCodeSelect" @selectCode="onSelect" class="areaCode" />
    </div>
</template>
<script>
import {
    verifyValidationCode,
    register,
    sendValidationCode,
    sendValidationEmail,
    verifyValidationEmail
} from '@/assets/js/apolloGql.js'
import AreaCode from '@/components/user/AreaCode.vue'
import { languageConfig } from '@/assets/js/langObject'
export default {
    components: {
        AreaCode
    },
    data() {
        return {
            isFocus: false,
            isSubmitDisabled: true,
            usrForm: {
                ft: '',
                in: '',
            },
            isShowAge: false,
            ext: this.$t('register.unit[0]'),
            isShowHeight: 0,
            erorTips: '',
            isAutoLogin: false,
            areaCode: '',
            showAreaCodeSelect: false,
            currentLanguage: '',
            languages: languageConfig,
            form: {
                name: '',
                phone: '',
                sex: 1,
                age: '',
                height: '',
                code: '',
                email: ''
            },
            name: '',
            age: '',
            code: '',
            height: {
                isValid: false,
                value: '',
            },
            birthday: {
                isValid: false,
                year: '',
                month: '',
                day: '',
            },
            // 验证码定时器
            timer: '',
            // 获取验证码时间间隔 60s
            countDown: 60,
            codeText: '',
            // 是否勾选同意协议
            isSelectRule: false,
            // 验证码默认不能输入 验证码发送成功后才能输入
            isCodeInputDisabled: false,
            // 验证码是否正在发送状态
            isCodeSending: false,
            // 注册用户信息页面
            isRegInfo: true,
            // 身高是否处于输入
            inputHeight: false,
            // 生日是否处于输入
            inputBirthday: false,
            outTimeStamp: 0,
            // 错误信息
            errorMg: '-',
            // 提交按钮状态
            submitDisabled: false,
            // 按钮开关
            isBtnHand: true,
            scanType: null,
            loginInfo: JSON.parse(window.localStorage.getItem('loginInfo')),
            // 单位
            unit: '',
            isAgeValid: false,
            isHeightValid: false,
            // 选择登录状态
            loginStatus: true
        }
    },

    computed: {
        // 是否禁用获取验证码按钮 手机号长度不对|正在倒计时|验证码已发送等待返回
        isCodeBtnDisabled() {
            return !this.form.phone || this.form.phone.length < 5 || this.form.phone.length > 15 || this.isCodeSending
        },
        isCodeBtnDisabledEmail() {
            return !this.form.email || this.isCodeSending
        },
        // 是否禁用手机号注册按钮
        isMobileSubmitDisabled() {
            console.log(this.form)
            return this.form.code.length !== 4
        },
        userAgent() {
            const ua = navigator.userAgent
            return {
                ua: ua,
                isAndroid: ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1,
                isiOS: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            }
        },
    },

    watch: {
        // 监听身高
        'height.value': {
            deep: true,
            // eslint-disable-next-line no-unused-vars
            handler: function (val, oldVal) {
                this.valideHeight(this.isShowHeight === 1 ? 'metric' : 'imperial')
                this.isHeightValid = this.isValidPass()
                if (this.isAgeValid && this.isHeightValid && this.isSelectRule) {
                    this.isSubmitDisabled = false
                } else {
                    this.isSubmitDisabled = true
                    if(!this.isAgeValid && this.isHeightValid) {
                        this.isAgeValid = this.validAge()
                    }
                }
            },
        },
        usrForm: {
            deep: true,
            handler() {
                this.valideHeight(this.isShowHeight === 1 ? 'metric' : 'imperial')
                // this.isAgeValid = this.validAge()
                this.isHeightValid = this.isValidPass()
                if (this.isAgeValid && this.isHeightValid && this.isSelectRule) {
                    this.isSubmitDisabled = false
                } else {
                    this.isSubmitDisabled = true
                    if(!this.isAgeValid && this.isHeightValid) {
                        this.isAgeValid = this.validAge()
                    }
                }
            },
        },
        age: {
            handler() {
                console.log(this.isValidPass(), this.validAge())
                this.isAgeValid = this.validAge()
                if (this.isAgeValid && this.isHeightValid && this.isSelectRule) {
                    this.isSubmitDisabled = false
                } else {
                    this.isSubmitDisabled = true
                    if(this.isAgeValid && !this.isHeightValid) {
                        this.valideHeight(this.isShowHeight === 1 ? 'metric' : 'imperial')
                    }
                }
                // 年龄为空校验
            },
        },
        // 监听生日
        birthday: {
            deep: true,
            // eslint-disable-next-line no-unused-vars
            handler: function (val, oldVal) {
                const yearReg = /^[1-2](9|0)\d{2}$/
                const monReg = /^(0?[1-9]|1[0-2])$/
                const dayReg = /^((0?[1-9])|((1|2)[0-9])|30|31)$/
                // 校验年份有效性
                if (val.year.length === 4) {
                    this.errorMg = yearReg.test(val.year) ? '-' : ''
                }
                // 校验月份有效性
                if (val.month) {
                    this.errorMg = monReg.test(val.month) ? '-' : ''
                }
                // 校验天份有效性
                if (val.day) {
                    this.errorMg = dayReg.test(val.day) ? '-' : ''
                }
                // 等待生日填写完整后 开始校验年龄范围
                if (val.year && val.month && val.day) {
                    this.valideBirthday()
                }
            },
        },
        isSelectRule: {
            handler: function (val) {
                if (this.isAgeValid && this.isHeightValid && val) {
                    this.isSubmitDisabled = false
                } else {
                    this.isSubmitDisabled = true
                }
            },
        }
    },

    beforeDestroy() {
        clearInterval(this.timer)
    },
    created() {
        if (this.$route.query.loginStatus === 'false') {
            this.loginStatus = false
        }
        this.codeText = this.$t('login.formList[0].placeholder')
        this.areaCode = this.languages.find((item) => item.value === this.$i18n.locale)?.no
        this.currentLanguage = this.languages.find((item) => item.value === this.$i18n.locale)?.label
        if (this.$route.query.mobile) {
            this.form.phone = this.$route.query.mobile
        }
        if (this.$route.query.email) {
            this.form.email = this.$route.query.email
        }
    },
    mounted() {
        // 保留信息不被清空
        const lookUserInfo = JSON.parse(window.localStorage.getItem('lookUserInfo'))
        console.log(`注册页获取账号信息${lookUserInfo}`)
        if (lookUserInfo) {
            this.form.phone = lookUserInfo.phone
            this.form.code = lookUserInfo.code
            this.form.email = lookUserInfo.email
            this.isCodeInputDisabled = false
        }
    },

    methods: {
        selectMobile() {
            this.form.code = ''
            this.clearTimer()
            this.loginStatus = true
            if (this.loginStatus) {
                this.form.email = ''
            } else {
                this.form.phone = ''
            }
        },
        selectEmail() {
            this.form.code = ''
            this.clearTimer()
            this.loginStatus = false
            if (this.form.phone) {
                this.form.phone = ''
            }
        },
        // 清空验证码
        clearTimer() {
            this.isCodeSending = false
            this.codeText = this.$t('login.formList[0].placeholder')
            clearInterval(this.timer)
            this.countDown = 60
        },
        selectAreaCode() {
            this.showAreaCodeSelect = true
        },
        onSelect(code) {
            if (code) this.areaCode = code
            this.showAreaCodeSelect = false
        },
        onRuleChange() {
            this.isSelectRule = !this.isSelectRule
            if (this.isSelectRule) {
                this.erorTips = ''
            }
        },
        // 去掉下次自动登录
        // onAutoLogin(){
        //     this.isAutoLogin = !this.isAutoLogin
        //     console.log('自动登入',  this.isAutoLogin)
        // },
        validAge() {
            if (this.age === '') {
                this.errorMg = this.$t('register.rules.ageMsg[0]')
            } else {
                if (this.age >= 10 && this.age <= 99) {
                    if (/^\d+$/.test(this.age)) {
                        this.errorMg = '-'
                        return true
                    } else {
                        this.errorMg = this.$t('register.rules.ageMsg[0]')
                    }
                    if (this.age.indexOf('.')) {
                        this.errorMg = this.$t('register.rules.agePointMsg[0]')
                        return true
                    } else {
                        this.errorMg = '-'
                    }
                } else {
                    this.errorMg = this.$t('register.rules.ageMsg[0]')
                }
            }
        },
        onPlaceholder() {
            this.isFocus = true
            this.$nextTick(() => {
                this.$refs.verifyCode.focus()
            })
        },
        isValidPass() {
            let isPass = false
            if (this.height.isValid) {
                isPass = true
            }
            return isPass
        },
        onBlurImperial() {
            if (!this.usrForm.ft && !this.usrForm.in) {
                this.isShowHeight = 0
            }
        },
        onBlurAge() {
            if (!this.age) {
                this.isShowAge = false
            }
        },
        onBlurMetric() {
            if (!this.height.value) {
                this.isShowHeight = 0
            }
        },
        onAgeFocus() {
            this.isShowAge = true
            this.$nextTick(() => {
                this.$refs.ageRef.focus()
            })
        },
        onExt() {
            this.errorMg="-"
            this.height.isValid = false
            this.height.value = ''
            this.usrForm.ft = ''
            this.usrForm.in = ''
            this.ext =
                this.ext === this.$t('register.unit[0]') ? this.$t('register.unit[1]') : this.$t('register.unit[0]')
            if (this.ext === this.$t('register.unit[0]')) {
                this.isShowHeight = 1
                this.$nextTick(() => {
                    this.$refs.metricRef.focus()
                })
            } else {
                this.isShowHeight = 2
                this.$nextTick(() => {
                    this.$refs.imperialRef.focus()
                })
            }
            // console.log(this.isValidPass())
            // this.isSubmitDisabled = this.isValidPass()
        },
        onHeightFocus() {
            if (this.ext === this.$t('register.unit[0]')) {
                this.isShowHeight = 1
                this.$nextTick(() => {
                    this.$refs.metricRef.focus()
                })
            } else {
                this.isShowHeight = 2
                this.$nextTick(() => {
                    this.$refs.imperialRef.focus()
                })
            }
        },
        onCommand(lang) {
            let languageInfo = {}
            for (let index = 0; index < this.languages.length; index++) {
                if (lang === this.languages[index].label) {
                    languageInfo = this.languages[index]
                    break
                }
            }
            this.$i18n.locale = languageInfo.value
            this.areaCode = languageInfo?.no
            if (this.isShowHeight === 0) {
                this.ext = this.$t('register.unit[0]')
            } else {
                this.ext = this.isShowHeight === 1 ? this.$t('register.unit[0]') : this.$t('register.unit[1]')
            }
            localStorage.setItem('lang', this.$i18n.locale)
            this.currentLanguage = lang
            this.codeText = this.$t('login.formList[0].placeholder')
        },
        // 失去焦点后重置背景
        handleBlurReset(e) {
            const phone = this.form.phone
            const email = this.form.email
            if (phone === '' || email === '') {
                if (this.loginStatus && phone === '') {
                    this.$toast({
                        message: this.$t('login.formList[0].label'),
                        duration: 1500,
                    })
                    return
                } else if (!this.loginStatus && email === '') {
                    this.$toast({
                        message: this.$t('login.formList[2].label'),
                        duration: 1500,
                    })
                    return
                }
            }
            if (this.loginStatus) {
                if (phone.length < 5 || phone.length > 15) {
                    this.erorTips = this.$t('login.rule.phone.requiredMsg')
                } else {
                    this.erorTips = ''
                }
            } else {
                // eslint-disable-next-line no-useless-escape
                const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!email.test(this.form.email)) {
                    this.erorTips = this.$t('login.rule.email.requiredMsg')
                } else {
                    this.erorTips = ''
                }
            }
            if (this.form.code === '') {
                this.isFocus = false
            }
            this.outTimeStamp = e.timeStamp
            setTimeout(() => {
                if (this.outTimeStamp === e.timeStamp) {
                    this.resetBackground()
                }
            }, 100)
        },

        handleFouceReset(e) {
            this.outTimeStamp = e.timeStamp
        },

        // 鼠标进入身高输入框区域
        handleHeightMouseEnter() {
            this.inputHeight = true
            // 身高为空 自动焦点到身高
            if (!this.height.value) {
                this.$refs.height.focus()
            }
        },

        // 身高输入框失去焦点
        handleBlurHeight(e) {
            this.inputHeight = false
            this.outTimeStamp = e.timeStamp
            setTimeout(() => {
                if (this.outTimeStamp === e.timeStamp) {
                    this.resetBackground()
                }
            }, 100)
        },
        // 最终身高
        finalHeight(unit) {
            if (unit === 'imperial') {
                if (this.usrForm.ft || this.usrForm.in) {
                    this.form.height = parseInt(this.usrForm.ft * 12, 10) + parseInt(this.usrForm.in, 10)
                }
            }
        },
        // 校验身高
        valideHeight(unit) {
            if (unit === 'imperial') {
                this.finalHeight(unit)
                const ft = this.usrForm.ft
                const _in = this.usrForm.in
                const ftRegName = /^[2-6]$/
                const inRegName = /^([0-9]|1[0-1])$/
                const ruleErrMsg = this.$t('register.rules.metricheight[0]')
                const cunErrMsg = this.$t('register.rules.metricheight[0]')
                if (!ft && _in) {
                    // 英寸
                    this.errorMg = cunErrMsg
                } else if (ft && !_in) {
                    // 英尺
                    this.errorMg = cunErrMsg
                } else if (ft && _in) {
                    // 英尺+英寸
                    if (!ftRegName.test(ft) || !inRegName.test(_in) || this.form.height < 27 || this.form.height > 79) {
                        this.errorMg = ruleErrMsg
                    } else {
                        this.height.isValid = true
                        this.errorMg = ''
                        return
                    }
                } else {
                    // 都未填写
                    this.errorMg = cunErrMsg
                }
                this.height.isValid = false
            } else if (unit === 'metric') {
                // 身高校验规则
                // const regex = /^(1[1-9]\d(\.\d+)?|20[0-4](\.\d+)?|205)$/;
                this.height.value = this.height.value.replace(/\.$/, "")
                if (/^\d+$/.test(this.height.value)) {
                    if (this.height.value >= 70 && this.height.value <= 200) {
                        this.height.isValid = true
                        this.errorMg = '-'
                        this.form.height = this.height.value
                    } else {
                        this.height.isValid = false
                        this.errorMg = this.$t('register.rules.heightMsg[0]')
                    }
                } else {
                    this.height.isValid = false
                    // this.errorMg = "允许输入的身高格式不支持小数，请重新输入";
                }
            }
        },

        // 鼠标进入生日输入区域
        handleClickBirthday(e) {
            this.outTimeStamp = e.timeStamp
            this.inputBirthday = true
            // 年份为空 自动焦点到年份
            if (!this.birthday.year) {
                this.$refs.year.focus()
            }
        },

        /**
         * 生日输入框事件处理
         *
         * @param {*} e
         * @param {number} type
         * @param {boolean} state true 获取焦点 false 移除焦点
         * @returns
         */
        handleEventBirthday(e, type, state) {
            this.outTimeStamp = e.timeStamp
            if (state === false) {
                if (type === 1) {
                    // 月份不为空则自动补0
                    this.birthday.month = this.birthday.month ? this.complete(this.birthday.month) : ''
                } else if (type === 2) {
                    // 天不为空则自动补0
                    this.birthday.day = this.birthday.day ? this.complete(this.birthday.day) : ''
                }
                setTimeout(() => {
                    if (this.outTimeStamp === e.timeStamp) {
                        this.inputBirthday = false
                        this.resetBackground()
                    }
                }, 100)
            }
        },

        // 生日依次输入跳转
        autoFocusNext(e, type) {
            // ios及安卓下的退格处理
            if (e.keyCode === 8) {
                // 年份为空仍删除则不处理
                if (type === 0 && this.birthday.year === '') {
                    e.preventDefault()
                    e.stopPropagation()
                }
                if (type === 1 && !this.birthday.month) {
                    this.$refs.year.focus()
                }
                if (type === 2 && !this.birthday.day) {
                    this.$refs.month.focus()
                }
                this.valideBirthday()
            } else {
                // 年份
                if (type === 0) {
                    // 自动跳转到月
                    if (this.birthday.year.length === 4) {
                        this.$refs.month.focus()
                        this.birthday.month = ''
                    }
                    // 月份
                } else if (type === 1) {
                    // 大于 1 时 自动跳转 否则等输入完两位
                    if (this.birthday.month > 1 || this.birthday.year.length === 2) {
                        this.$refs.day.focus()
                        this.birthday.day = ''
                    }
                }
            }
        },

        // 校验生日有效性
        valideBirthday() {
            const birthStr = `${this.birthday.year}-${this.complete(this.birthday.month)}-${this.complete(
                this.birthday.day
            )}`

            const date = this.$moment(birthStr, this.$moment.ISO_8601)
            if (date.isValid()) {
                const age = this.getAge(birthStr)
                if (age < 10 || age > 70) {
                    this.birthday.isValid = false
                    this.errorMg = '允许测量的年龄范围为10-70岁，请重新输入'
                } else {
                    this.birthday.isValid = true
                    this.errorMg = '-'
                    this.form.birthday = birthStr
                }
            } else {
                this.birthday.isValid = false
                // this.errorMg = '请填写正确的生日'
                this.errorMg = ''
            }
        },

        // 根据输入的生日获取年龄
        getAge(birthday) {
            let age
            const birthdayArr = birthday.split('-')
            const birthdayYear = parseInt(birthdayArr[0], 10)
            const birthdayMonth = parseInt(birthdayArr[1], 10)
            const birthdayDay = parseInt(birthdayArr[2], 10)
            const today = new Date()
            const nowYear = today.getFullYear()
            const nowMonth = today.getMonth() + 1
            const nowDay = today.getDate()
            if (nowYear === birthdayYear) {
                age = 0 // 同年 则为0岁
            } else {
                const ageDiff = nowYear - birthdayYear // 年之差
                if (ageDiff > 0) {
                    if (nowMonth === birthdayMonth) {
                        const dayDiff = nowDay - birthdayDay // 日之差
                        if (dayDiff < 0) {
                            age = ageDiff - 1
                        } else {
                            age = ageDiff
                        }
                    } else {
                        const monthDiff = nowMonth - birthdayMonth // 月之差
                        if (monthDiff < 0) {
                            age = ageDiff - 1
                        } else {
                            age = ageDiff
                        }
                    }
                } else {
                    age = -1 // 返回-1 表示出生日期输入错误 晚于今天
                }
            }
            return age // 返回周岁年龄
        },

        // 月、日自动补0
        complete(num) {
            let result
            result = num ? (num.length === 1 ? '0' + num : num) : '00'
            return result
        },

        // 重置iso下键盘收回后底部空白问题
        resetBackground() {
            window.scrollTo(0, 0)
        },

        // 发送验证码
        handleSendCode() {
            let that = this
            // // 判断按钮状态 禁用时不可点击
            if (this.isCodeBtnDisabled && this.loginStatus) {
                return
            } else if (this.isCodeBtnDisabledEmail && !this.loginStatus) {
                return
            }
            that.form.code = ''
            const reg = /^[0-9]*$/
            // eslint-disable-next-line no-useless-escape
            const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.countDown === 60) {
                if (!reg.test(this.form.phone) && this.loginStatus) {
                    this.erorTips = this.$t('login.rule.phone.requiredMsg')
                } else if (!email.test(this.form.email) && !this.loginStatus) {
                    this.erorTips = this.$t('login.rule.email.requiredMsg')
                } else {
                    this.isCodeSending = true
                    // 验证码发送成功后可输入验证码
                    this.isCodeInputDisabled = false
                    let timer = setInterval(() => {
                        that.countDown--
                        that.codeText = `${that.countDown}${this.$t('login.retransmission')}`
                        if (that.countDown === 0) {
                            that.isCodeSending = false
                            that.codeText = this.$t('login.formList[0].placeholder')
                            clearInterval(timer)
                            that.countDown = 60
                        }
                    }, 1000)
                    this.timer = timer
                    if (this.loginStatus) {
                        this.$apollo
                            .mutate({
                                mutation: sendValidationCode,
                                variables: {
                                    mobile: this.areaCode + this.form.phone,
                                },
                            })
                            .then((res) => {
                                const data = res.data.sendValidationCode
                                if (data.code === 200) {
                                    this.$toast({
                                        message: this.$t('login.success'),
                                        duration: 2000,
                                    })
                                } else {
                                    this.isCodeSending = false
                                    this.$toast({
                                        message: this.$t('login.error'),
                                        duration: 2000,
                                    })
                                }
                            })
                            .catch((err) => {
                                console.error(err)
                                this.isCodeSending = false
                            })
                    } else {
                        this.$apollo
                            .mutate({
                                mutation: sendValidationEmail,
                                variables: {
                                    email: this.form.email,
                                },
                            })
                            .then((res) => {
                                const data = res.data.sendValidationEmail
                                if (data.code === 200) {
                                    this.$toast({
                                        message: this.$t('login.success'),
                                        duration: 2000,
                                    })
                                } else if (data.code === 404) {
                                    // 邮箱未注册提示文案
                                    this.erorTips = this.$t('login.rule.email.patternMsg')
                                } else {
                                    this.isCodeSending = false
                                    this.$toast({
                                        message: this.$t('login.error'),
                                        duration: 2000,
                                    })
                                }
                            })
                            .catch((err) => {
                                console.error(err)
                                this.isCodeSending = false
                            })
                    }
                }
            }
        },

        // 清空手机号
        handleClearMobile() {
            if (this.form.phone) {
                this.form.phone = ''
                this.form.code = ''
                console.log('清空手机号')
                window.localStorage.removeItem('lookUserInfo')
            }
        },
        validateForm() {
            let err

            if (!this.isSelectRule) {
                err = this.$t('register.check')
            }

            if (!this.form.code) {
                err = this.$t('login.formList[1].placeholder')
            }

            // 判断验证码少于4位
            if (this.form.code && this.form.code.length < 4) {
                err = this.$t('login.rule.authCode.requiredMsg')
            }

            if (!this.form.phone && !this.form.email) {
                if (this.loginStatus) {
                    err = this.$t('login.formList[0].label')
                } else {
                    err = this.$t('login.formList[2].label')
                }

            } else {
                if (this.loginStatus) {
                    const reg = /^[0-9]*$/
                    if (!reg.test(this.form.phone)) {
                        err = this.$t('login.rule.phone.requiredMsg')
                    }
                } else {
                    // eslint-disable-next-line no-useless-escape
                    const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!email.test(this.form.email)) {
                        err = this.$t('login.rule.email.requiredMsg')
                    }
                }
            }
            if (err) {
                this.erorTips = err
                return false
            }
            return true
        },
        // 手机号注册
        handleMobileSumbit() {
            if (!this.isMobileSubmitDisabled && this.validateForm()) {
                let mobile = ''
                if (this.form.phone) {
                    mobile = this.areaCode + this.form.phone
                }
                if (mobile) {
                    this.$apollo
                        .query({
                            query: verifyValidationCode,
                            fetchPolicy: 'network-only',
                            variables: {
                                mobile: mobile,
                                email: this.form.email,
                                code: String(this.form.code) || 0,
                            },
                        })
                        .then((res) => {
                            const data = res.data.verifyValidationCode
                            // 老用户 已有用户信息
                            if (data.code === 200) {
                                this.isRegInfo = true
                            } else if (data.code === 405) {
                                this.erorTips = this.$t('register.enroll')
                            } else if (data.code === 413) {
                                this.erorTips = this.$t('login.rule.authCode.requiredMsg')
                            } else {
                                // 其他 登录失败
                                this.erorTips = data.message
                            }
                        })
                } else {
                    this.$apollo
                        .query({
                            query: verifyValidationEmail,
                            fetchPolicy: 'network-only',
                            variables: {
                                email: this.form.email,
                                code: String(this.form.code) || 0,
                            },
                        })
                        .then((res) => {
                            const data = res.data.verifyValidationEmail
                            // 老用户 已有用户信息
                            if (data.code === 200) {
                                this.isRegInfo = true
                            } else if (data.code === 405) {
                                this.erorTips = this.$t('register.enrollEmail')
                            } else if (data.code === 413) {
                                this.erorTips = this.$t('login.rule.authCode.requiredMsg')
                            } else if (data.code === 500) {
                                this.erorTips = this.$t('register.enroll')
                            } 
                            else {
                                // 其他 登录失败
                                this.erorTips = data.message
                            }
                        })
                }

            }
        },

        // 判断验证码长度
        validatorCode() {
            const code = this.form.code.replace(/\s*/g, "")
            if (code.length > 4) {
                this.form.code = code.substring(0, 4)
                this.$toast({
                    message: this.$t('register.verify'),
                    duration: 2000,
                })
            }
        },

        // 跳转到用户使用/隐私协议
        goToUser(pathName) {
            this.$router.push({
                name: pathName,
            })
            if (this.form.phone || this.form.code) {
                const lookInfo = window.localStorage.getItem('lookUserInfo') ? JSON.parse(window.localStorage.getItem('lookUserInfo')) : {}
                const info = {
                    ...lookInfo,
                    ...this.form
                }
                window.localStorage.setItem('lookUserInfo', JSON.stringify(info))
            }
        },

        // 用户信息提交
        handleInfoSumbit() {
            if (!this.isSubmitDisabled && this.isBtnHand) {
                const lookUserInfo = JSON.parse(window.localStorage.getItem('lookUserInfo'))
                console.log(`注册页获取账号信息${lookUserInfo}`)
                if (lookUserInfo) {
                    this.form.phone = lookUserInfo.phone
                    this.form.code = lookUserInfo.code
                    this.form.email = lookUserInfo.email
                    this.form.areaCode = lookUserInfo.areaCode
                }
                if(!lookUserInfo) {
                    this.$toast({
                        message: this.$t('register.abnormalMsg'),
                        duration: 2000,
                    })
                    this.$router.push({
                        path: '/login',
                    })
                    return
                }
                let mobile = ''
                let email = ''
                if (this.form.phone) {
                    mobile = this.form.areaCode + this.form.phone
                } else {
                    email = this.form.email
                }
                this.submitDisabled = true
                const param = {
                    mobile: mobile,
                    name: this.name,
                    sex: parseInt(this.form.sex, 10) || 0,
                    height:
                        this.ext === this.$t('register.unit[1]')
                            ? parseInt(this.usrForm.ft * 12, 10) + parseInt(this.usrForm.in, 10)
                            : Number((this.form.height / 2.54).toFixed(1)),
                    age: parseInt(this.age, 10),
                    email: email
                }
                console.log(param)
                console.log('param', param)
                this.$apollo
                    .mutate({
                        mutation: register,
                        variables: {
                            member: param,
                        },
                    })
                    .then((res) => {
                        this.submitDisabled = false
                        this.isBtnHand = false
                        const data = res.data.register
                        if (data.code === 200) {
                            if (!this.loginStatus) {
                                localStorage.setItem('email', email)
                            }
                            // 保存token
                            sessionStorage.setItem('token', data.data.token)
                            if (this.isAutoLogin) {
                                // 设置自动登录
                                localStorage.setItem('token', data.data.token)
                            }
                            window.localStorage.removeItem('lookUserInfo')
                            window.localStorage.removeItem('modelInfo')
                            this.$toast({
                                message: this.$t('register.success'),
                                duration: 2000,
                                // onClose: () => {
                                //     window.localStorage.setItem('newUser', true)
                                //     // 注册成功默认跳转到无报告页面
                                //     let path = '/exception/report';
                                //     // 如果是从别的页面跳转过来的，跳回之前的页面
                                //     if (this.$route.query.redirect) {
                                //         path = this.$route.query.redirect   // 跳到之前的页面
                                //     }
                                //     this.$router.push({
                                //         path: path
                                //     });
                                // }
                            })
                            setTimeout(() => {
                                window.localStorage.setItem('newUser', true)
                                // 注册成功默认跳转到无报告页面
                                let path = '/exception/report';
                                // 如果是从别的页面跳转过来的，跳回之前的页面
                                if (this.$route.query.redirect) {
                                    path = this.$route.query.redirect   // 跳到之前的页面
                                }
                                this.$router.push({
                                    path: path
                                }, 3000);
                            })
                        } else if (data.code === 404) {
                            // 当前微信号下没有报告
                            this.$router.push({
                                path: '/exception/report',
                            })
                        } else {
                            this.$toast({
                                message: data.message,
                                duration: 2000,
                            })
                        }
                    })
            } else {
                if (!this.birthday.isValid) {
                    this.valideBirthday()
                }

                if (!this.height.isValid) {
                    this.valideHeight()
                }
            }
        }
    },
}
</script>

<style scoped lang="less">
.register-page {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    background-image: linear-gradient(180deg, #262849, #07090f);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // justify-content: space-between;
    justify-content: initial;

    .areaCode {
        z-index: 999;
    }

    .navbar {
        width: 100%;
        height: 30px;
        padding-right: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .back {
            font-size: 12px;
            padding-left: 21px;
            color: #fff;
        }

        /deep/ .van-icon-arrow-left:before {
            font-size: 14px;
            color: #fff;
        }
    }

    .el-dropdown-language {
        font-size: 12px;
        color: #fff;

        &::after {
            content: '';
            position: absolute;
            top: 45%;
            right: -14px;
            transform: translateY(-80%);
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #fff;
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding-top: 60.5px;
        // padding-bottom: 38.5px;

        h2 {
            // width: 205px;
            height: 24px;
            mix-blend-mode: initial;
            color: #FFFFFF;
            // background-image: linear-gradient(to right, #00e3c9, #00a4e6), linear-gradient(#ffffff, #ffffff);
            font-family: PingFangSC;
            font-size: 21px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            -webkit-background-clip: text;
            background-clip: text;
            // -webkit-text-fill-color: transparent;
        }

        p {
            width: 168px;
            height: 34px;
            font-family: PingFangSC;
            mix-blend-mode: initial;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #8AA3BE;
            margin-top: 8px;
            margin-bottom: 58px;
        }

        .tips {
            width: 180px;
        }
    }

    .login {
        display: flex;
        justify-content: space-between;
        width: 172px;
        font-size: 15px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #8AA3BE;
        line-height: 15px;
        margin-bottom: 43px;

        .icon {
            width: 1px;
            height: 19px;
            color: #373955;
        }

        .mobile-login {
            width: 60px;
            height: 15px;
            font-size: 15px;
            font-family: PingFangSC;
            font-weight: bold;
            color: #8AA3BE;
            line-height: 15px;
            padding-bottom: 15px;
            border-bottom: 2px solid #009FE8;
            background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    /deep/ .content .form .item {
        input:-internal-autofill-previewed,
        input:-internal-autofill-selected {
            -webkit-text-fill-color: #FFFFFF !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }

    }

    .content {

        // flex: 1;
        // 表单
        .form {
            width: 100%;
            height: auto;

            .itemName {
                padding: 0 13px 0 26px !important;
            }

            .item {
                position: relative;
                width: 345px;
                height: 56px;
                margin: 0 auto;
                border-radius: 4px;
                // background-color: #232440;
                background: #303C5C;
                padding: 0 16px;
                color: rgba(255, 255, 255, 1);
                margin-bottom: 8px;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.24);
                display: flex;
                align-items: center;
                box-sizing: border-box;

                .age {
                    width: 110px;
                    // /deep/ .van-field__control {
                    //     margin-right: 0 !important;
                    // }
                }

                .default_height {
                    display: flex;
                    // background-color: #fe6363;
                    align-items: center;

                    .placeholder_code {
                        width: 200px;
                        font-size: 14px;
                        color: #6c6c6f;
                        text-align: left;
                        padding-left: 25px;
                    }
                }

                .metric {
                    display: flex;
                    align-items: center;
                    width: 125px;
                    // /deep/ .van-field__value {
                    //     max-width: 100px;
                    //     margin-left: 45px;
                    // }
                    // /deep/ .van-field__control {
                    //     padding-left: 42px;
                    // }
                }

                .unit {
                    font-size: 12px;
                    color: #6c6c6f;
                }

                .imperial {
                    width: 190px;

                    // /deep/ .van-field__value {
                    //     max-width: 100px;
                    //     margin-left: 0px;
                    // }
                    /deep/ .van-field__control {
                        // padding-left: 40px;
                        // margin-left: 30px;
                        text-align: center;
                        // margin-right: -140px !important;
                    }

                    .subItem {
                        display: flex;
                        align-items: center;
                        // /deep/ .van-field__control {
                        //     max-width: 70px;
                        // }
                    }

                    .subItem1 {
                        // /deep/ .van-field__control {
                        //     padding-left: 0;
                        //     max-width: 25px;
                        //     margin-left: 15px;
                        // }
                    }
                }

                .ext {
                    position: absolute;
                    right: 10px;
                    min-width: fit-content;
                    font-size: 11px;
                    color: #6c6c6f;

                    img {
                        width: 14px;
                        height: 12px;
                        vertical-align: middle;
                    }
                }

                .van-cell {
                    background: #303C5C;
                    // background-color: #232440;
                    padding: 0;
                    /deep/ .van-field__label {
                        width: fit-content;

                        span {
                            white-space: nowrap;
                            color: #fff;
                            display: inline-block;
                            width: 40px;
                            text-align-last: justify;
                        }
                    }

                    /deep/ .van-field__control {
                        color: #fff;
                        margin-left: 22px;
                    }

                    /deep/ .van-field__control::placeholder {
                        color: #6c6c6f;
                    }
                }

                .area {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .no {
                        margin-right: 7px;
                    }

                    .arrow_bottom {
                        width: 0;
                        height: 0;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-top: 6px solid #fff;
                    }
                }

                .info {
                    position: relative;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    /deep/ .van-field {
                        padding-left: 0;
                        // background-color: #232440;
                        background: #303C5C;
                    }

                    .placeholder_code {
                        width: 180px;
                        font-size: 14px;
                        color: #6c6c6f;
                        text-align: left;
                        padding-left: 20px;
                        padding-right: 30px;
                    }

                    /deep/ .van-field__control {
                        color: #fff;
                        margin-left: 46px;
                    }

                    /deep/ .van-field__control::placeholder {
                        color: #6c6c6f;
                    }

                    &.center {
                        // justify-content: center;
                        justify-content: left;
                    }

                    .over {
                        position: absolute;
                        z-index: 10;
                        width: 100%;
                        height: 56px;
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.5);
                        line-height: 56px;
                        // pointer-events: none;
                        background-color: #303C5C;
                    }
                }

                label {
                    font-size: 14px;
                    text-align: left;
                }

                input {
                    width: 100%;
                    height: 56px;
                    background: transparent;
                    padding-left: 46px;
                    font-size: 14px;
                    color: #fff;

                    &[name='birthday'] {
                        flex: none;
                        width: 50px;
                    }
                }

                /deep/ .van-field__control {
                    // padding-left: 46px;
                }

                input[name='height']~span {
                    font-size: 12px;
                    line-height: 12px;
                    color: rgba(255, 255, 255, 0.5);
                }

                .itemSex {
                    .sex {
                        display: inline-block;
                        opacity: 0.5;
                        width: 65px;
                        height: 30px;
                        font-size: 14px;
                        line-height: 30px;
                        text-align: center;
                        margin-left: 45px;
                        border-radius: 4px;
                        border: 1px solid rgba(255, 255, 255, 1);

                        &.is-active {
                            color: #222222;
                            opacity: 1;
                            border: 1px solid transparent;
                            background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
                            // background-image: linear-gradient(#191b31, #191b31),
                            //     /* 底色，即原有的背景 */ linear-gradient(to right, #06b0a2, #067eb9); /* 模拟渐变边框 */
                            background-clip: padding-box, border-box;
                            background-origin: border-box;
                        }
                    }

                    .sex2 {
                        margin-left: 15px;
                    }
                }

                .get-code-btn {
                    min-width: fit-content;
                    font-size: 12px;
                    color: #fff;

                    &.is-disabled {
                        opacity: 0.5;
                    }
                }
            }
        }

        &.user-info {
            .item {
                input {
                    flex: 1;
                    text-align: center;
                    padding-left: 0px;
                }
            }

            // /deep/ .van-field__control {
            //     text-align: center;
            // }
        }
    }

    .content {
        .form {
            .rule {
                color: #fff;
                margin: 16px auto;
                padding: 0 10px;
                height: auto;
                font-size: 12px;
                display: flex;
                align-items: center;

                // justify-content: center;
                i {
                    font-style: normal;
                }

                .checkbox {
                    position: relative;
                    height: 14px;
                    width: 14px;
                    border-radius: 50%;
                    margin-right: 5px;
                    border: 1px solid #fff;

                    input {
                        height: 14px;
                        width: 14px;
                        border-radius: 50%;
                        opacity: 0;
                        text-align: center;
                    }

                    &.is-check {
                        &:before {
                            position: relative;
                            content: '';
                            height: 14px;
                            width: 14px;
                            left: -1px;
                            top: -1px;
                            margin: auto;
                            border-radius: 50%;
                            // background: #00a4e6;
                            display: block;
                            overflow: hidden;
                            background: url('../../assets/images/comp_rb.png') no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }

                span {
                    color: #4a90e2;
                    z-index: 999;
                    display: inline-block;
                }
            }

            .opacity {
                opacity: 0;
            }

            // 提交按钮
            .submit-btn {
                position: relative;
                margin-top: 50px;

                .complete {
                    padding: 0;
                    margin-top: 20px;
                    width: 345px;
                    height: 40px;
                    opacity: 0.3;

                    /deep/ .van-button__content {
                        width: 345px;
                        height: 40px;
                        background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                        border-radius: 6px;
                    }
                }

                .no-disabled {
                    opacity: 1;
                    color: #222222;
                }

                .error-tips {
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 12px;
                    color: #fe6363;
                    width: 100%;
                }

                button {
                    width: 200px;
                    height: 40px;
                    outline: none;
                    border-radius: 6px;
                    background-image: -webkit-gradient(linear, left top, right top, from(#00E3C9), to(#009FE8));
                    background-image: -webkit-linear-gradient(left, #00E3C9, #009FE8);
                    background-image: -o-linear-gradient(left, #00E3C9, #009FE8);
                    background-image: linear-gradient(to right, #00E3C9, #009FE8);
                    font-size: 16px;
                    color: #fff;

                    &.is-disabled {
                        border: 1px solid transparent;
                        background-image: linear-gradient(#191b31, #191b31),
                            /* 底色，即原有的背景 */
                            linear-gradient(to right, #00E3C9, #009FE8);
                        /* 模拟渐变边框 */
                        background-clip: padding-box, border-box;
                        background-origin: border-box;
                    }

                    .spinnerBtn {
                        display: flex;
                        justify-content: center;
                    }

                    /deep/ .mint-spinner-snake {
                        border: 2px solid transparent;
                    }

                    &.custom-btn {
                        width: 345px;
                        height: 40px;
                        background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                        border-radius: 6px;
                    }
                }
            }
        }
    }

    .icon-del {
        float: right;
        width: 9.5px;
        height: 9.5px;
        border-radius: 0.8px;
        background: url('../../assets/images/register/register_phone_delete.png') no-repeat;
    }

    .tips {
        position: relative;
        display: inline-block;
        font-size: 12px;
        height: 12px;
        line-height: 12px;
        min-width: 10px;
        color: #fff;
        // color: #fe6363;
        margin-bottom: 30px;
        margin-top: 10px;

        &::before {
            // content: '*';
            position: absolute;
            width: 5px;
            left: -8px;
            top: 2px;
        }
    }

    /deep/ .item:nth-child(2) {
        .info {
            .van-field__body {
                .van-field__control {
                    margin-left: 48px;
                }
            }
        }
    }
    // &.pt-PTO{
    //    /deep/ .user-info{
    //         .van-field__body{
    //             width: 206px !important;
    //         }
    //     }
    // }
    &.ar-AR{
                        /deep/ .van-field__control{
                            margin-right: 22px !important;
                            margin-left: 0 !important;
                        }
                    }
    &.ja-JP {
        .header {
            .tipsInfo {
                width: 225px;
            }

            .tips {
                width: 300px;
                // line-height: 17px;
            }
        }

        /deep/ .content .form .item {
            .get-code-btn {
                min-width: 60px;
                text-align: left;
            }

            .van-field__control {
                // padding-left: 46px;
            }
        }

        /deep/ .item:nth-child(2) {
            .info {
                .van-field__control {
                    margin-left: 22px;
                }
            }
        }

        /deep/ input[name='verifyCode'] {
            width: 104px;
            line-height: 20px;
            white-space: normal;
            word-break: break-all;
            word-wrap: break-word;
        }

        div .error-tips {
            width: 314px;
            height: 34px;
            margin-top: 10px;
            // line-height: 34px;
        }

        .custom-btn {
            margin-top: 20px !important;
        }
    }

    &.en-US,
    &.it-IT,
    &.fr-FR,
    &.ru-RU,
    &.es-ES,
    &.pt-PT,
    &.de-DE{
        .header {
            .tipsInfo {
                width: 312px;
                line-height: 17px;
            }

            .tips {
                width: 312px;
            }
        }

        .checkbox {
            border: 1px solid #8AA3BE !important;
        }

        i {
            color: #8AA3BE;
        }

        .itemSex {
            .sex {
                margin-left: 22px !important;
            }

            .sex2 {
                margin-left: 15px !important;
            }
        }
    }
    &.id-ID{
        .van-cell{
            width: 115% !important;
        }
        .item{
            width: 365px !important;
        }
        .firstItem{
         
            /deep/.unit{
            position: absolute;
            left: 150px;
            z-index: 999;
        }
        }
        //
        /deep/.van-field__control{
            margin-left: 44px !important;
        }
        /deep/.unit{
            margin-right: -15px !important;
        }
    }
}
.pt-PTO{
    .itemSpan{
        .span-cm{
            flex: 1;
        }
    }
     .label-item{
        width: 80px;
    
}
}
.ar-AR{
    direction: rtl;
      /deep/.el-dropdown{
        margin-left: 20px;}
    .header{
        p{
            text-align: right;
        }
    }
    .navbar{
        padding-right: 0;
        .back {
    padding-right: 23px;
    -webkit-transform: scaleX(-1);
}
    }
    .form .item .ext{
        right: 283px !important;
    }
    .rule{
        .checkbox{
            margin-left: 5px;
 &.is-check {
              &:before {   
                    left: 1px !important;
                        }
                    }
        }
        
    }
    
        
}
</style>
<style>
input[type=number] {
    -moz-appearance: textfield;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #303C5C inset;
    -webkit-text-fill-color: #fff;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected {
    -webkit-text-fill-color: #303C5C;
    transition: background-color 5000s ease-out 0.5s;
}

::-webkit-input-placeholder {
    color: #6c6c6f
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #6c6c6f
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #6c6c6f
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #6c6c6f;
}
</style>
